import { useEffect, useMemo, useRef } from "react";

import { usePrivy } from "@privy-io/react-auth";

import useAccountInfo from "src/hooks/apis/useAccountInfo";
import useUpdateUser from "src/hooks/apis/useUpdateUser";

const PrivyWatcher = () => {
  const { user, createWallet } = usePrivy();

  const { data: accountInfo } = useAccountInfo();
  const { trigger: updateUser } = useUpdateUser();

  const currentWallets = useMemo(() => user?.linkedAccounts?.filter(o => o.type === "wallet"), [user?.linkedAccounts]);
  const oldWallets = useRef(currentWallets);

  useEffect(() => {
    if (accountInfo) {
      if (currentWallets?.length === 0) createWallet();
    }
  }, [accountInfo, currentWallets, createWallet]);

  useEffect(() => {
    if (oldWallets.current && currentWallets?.length > oldWallets.current.length) {
      updateUser({ privy_updated: true });
    }
    oldWallets.current = currentWallets;
  }, [currentWallets, updateUser]);

  return null;
};

export default PrivyWatcher;
