import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privyLogin    : ["email"],
  currentDialog : {
    name  : null,
    props : null,
  }
};

const slice = createSlice({
  name     : "global",
  initialState,
  reducers : {
    setPrivyLogin    : (state, action) => ({ ...state, privyLogin: action.payload }),
    setCurrentDialog : (state, action) => ({ ...state, currentDialog: action.payload })
  }
});

export const { setPrivyLogin, setCurrentDialog } = slice.actions;

export default slice.reducer;
