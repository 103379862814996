import { useEffect } from "react";

import { DIALOG_NAME, WEBSOCKET_EVENT } from "src/constants";
import useWebSocket from "src/hooks/useWebSocket";
import useGlobalDialog from "src/redux/hooks/useGlobalDialog";

const WebhookEventHandler = () => {
  const { lastMessage } = useWebSocket();
  const { setData: setCurrentDialog } = useGlobalDialog();

  useEffect(() => {
    const message = lastMessage?.data;
    if (message) {
      const data = JSON.parse(message);
      if (data.event === WEBSOCKET_EVENT.RESET_KYC) {
        setCurrentDialog(DIALOG_NAME.KYC_RESET);
      }
      else if (data.event === WEBSOCKET_EVENT.RESET_COUNTRY) {
        setCurrentDialog(DIALOG_NAME.COUNTRY_RESET);
      }
      else if (data.event === WEBSOCKET_EVENT.ACCOUNT_BLOCKED) {
        setCurrentDialog(DIALOG_NAME.ACCOUNT_BLOCKED);
      }
      else if (data.event === WEBSOCKET_EVENT.ACCOUNT_UNBLOCKED) {
        setCurrentDialog(DIALOG_NAME.ACCOUNT_UNBLOCKED);
      }
    }
  }, [lastMessage, setCurrentDialog]);

  return null;
};

export default WebhookEventHandler;
