import React, { Suspense, lazy } from "react";

import PropTypes from "prop-types";

import { LoadingSpinner } from "src/components";
import { DIALOG_NAME } from "src/constants";
import useGlobalDialog from "src/redux/hooks/useGlobalDialog";

const WalletSettingsDialog = lazy(() => import("src/components/Dialogs/WalletSettingsDialog"));
const KYCFlowDialog = lazy(() => import("src/components/Dialogs/KYCFlowDialog"));
const KYCResetDialog = lazy(() => import("src/components/Dialogs/KYCResetDialog"));
const KYCStatusDialog = lazy(() => import("src/components/Dialogs/KYCStatusDialog"));
const KYCFinishedDialog = lazy(() => import("src/components/Dialogs/KYCFinishedDialog"));
const KYBReviewDialog = lazy(() => import("src/components/Dialogs/KYBReviewDialog"));
const CountryResetDialog = lazy(() => import("src/components/Dialogs/CountryResetDialog"));
const AccountBlockedDialog = lazy(() => import("src/components/Dialogs/AccountBlockedDialog"));
const AccountUnblockedDialog = lazy(() => import("src/components/Dialogs/AccountUnblockedDialog"));
const AccessUnavailableDialog = lazy(() => import("src/components/Dialogs/AccessUnavailableDialog"));
const VpnBlockedDialog = lazy(() => import("src/components/Dialogs/VpnBlockedDialog"));
const SeriesPausedDialog = lazy(() => import("src/components/Dialogs/SeriesPausedDialog"));
const MaxAmountReachedDialog = lazy(() => import("src/components/Dialogs/Investment/MaxAmountReachedDialog"));
const UnlinkWalletDialog = lazy(() => import("src/components/Dialogs/UnlinkWalletDialog"));
const GrantAcceptSuccessDialog = lazy(() => import("src/components/Dialogs/GrantAcceptSuccessDialog"));

const DialogWrapper = ({ context, value }) => {
  const { data: currentDialog, setData: setCurrentDialog } = useGlobalDialog();

  // No open dialog
  if (!currentDialog?.name) return null;

  return (
    <Suspense fallback={<LoadingSpinner />}>
      {currentDialog.name === DIALOG_NAME.WALLET_SETTINGS && (
        <WalletSettingsDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.KYC_RESET && (
        <KYCResetDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.KYC_FLOW && (
        <KYCFlowDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.KYC_FINISHED && (
        <KYCFinishedDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.KYC_STATUS && (
        <KYCStatusDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.KYB_REVIEW && (
        <KYBReviewDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.COUNTRY_RESET && (
        <CountryResetDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.ACCOUNT_BLOCKED && (
        <AccountBlockedDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.ACCOUNT_UNBLOCKED && (
        <AccountUnblockedDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.ACCESS_UNAVAILABLE && (
        <AccessUnavailableDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.VPN_BLOCKED && (
        <VpnBlockedDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.SERIES_PAUSED && (
        <SeriesPausedDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.MAX_AMOUNT_REACHED && (
        <MaxAmountReachedDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.UNLINK_WALLET && (
        <UnlinkWalletDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.GRANT_ACCEPT_SUCCESS && (
        <GrantAcceptSuccessDialog context={context} value={value} onClose={() => setCurrentDialog(null)} />
      )}
    </Suspense>
  );
};

DialogWrapper.propTypes = {
  context : PropTypes.string.isRequired,
  value   : PropTypes.string.isRequired
};

export default DialogWrapper;
