import { mutate } from "swr";

import { SERVICE_1, callLambda } from "src/utils/lambda";

import useAuthSWR from "./useAuthSWR";

export default () => {
  const invitationCode = window.sessionStorage.getItem("invitationCode");
  return useAuthSWR("accountInfo", () => callLambda({
    method                : "get",
    service               : SERVICE_1,
    url                   : "getAccountInfo",
    queryStringParameters : { invitationCode },
  }), {
    onError: () => {
      mutate("accountInfo");
    }
  });
};
