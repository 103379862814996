import { createGlobalStyle } from "styled-components";

export const HideScrollBar = createGlobalStyle`
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;
