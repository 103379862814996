import React, { Suspense, useEffect, useMemo } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { PrivyProvider } from "@privy-io/react-auth";
import get from "lodash/get";
import PropTypes from "prop-types";
import TagManager from "react-gtm-module";
import { Toaster } from "react-hot-toast";

import { LoadingSpinner } from "src/components";
import { config_gtmId, config_privyAppId } from "src/config";
import usePortalItem from "src/hooks/apis/usePortalItem";
import usePrivyLoginMethod from "src/redux/hooks/usePrivyLoginMethod";
import { createTheme } from "src/theme";
import loadIntercom from "src/utils/intercom";

import Content from "./content";

if (config_gtmId) {
  TagManager.initialize({ gtmId: config_gtmId });
}

const CoreView = ({ hasError }) => {
  const { data } = usePortalItem();
  const portalItem = data?.portalItem;

  const { data: loginMethods } = usePrivyLoginMethod();

  useEffect(() => {
    loadIntercom();
  }, []);

  const seriesTheme = get(portalItem, "series.attributes.theme", null);
  const currentTheme = seriesTheme || portalItem?.attributes.theme;
  const currentLogos = seriesTheme?.logos || portalItem?.logos;
  const theme = useMemo(() => createTheme({
    direction           : "ltr",
    paletteMode         : "light",
    responsiveFontSizes : true,
    roundedCorners      : true,
    colorPreset         : "indigo",
    contrast            : "high",
    orgTheme            : currentTheme
  }), [currentTheme]);

  useEffect(() => {
    if (portalItem) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      const favicon = get(portalItem, "series.attributes.theme.logos.square_colored", get(portalItem, "logos.square_colored"));
      link.href = favicon;
    }
  }, [portalItem]);

  // Render
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrivyProvider
          appId={config_privyAppId}
          config={{
            loginMethods,
            embeddedWallets: {
              noPromptOnSignature: true
            },
            appearance: {
              logo                 : currentLogos?.rectangle_colored,
              theme                : "light",
              accentColor          : currentTheme?.primary,
              showWalletLoginFirst : false
            }
          }}
        >
          <Content
            portalItem={portalItem}
            hasError={hasError}
          />
        </PrivyProvider>
        <Toaster toastOptions={{ error: { duration: 10000 } }} />
      </ThemeProvider>
    </Suspense>
  );
};

class ViewWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error("[series app] general error: ", error);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    return <CoreView hasError={hasError} {...this.props} />;
  }
}

CoreView.propTypes = {
  hasError: PropTypes.bool,
};

export default ViewWrapper;
