import { usePrivy } from "@privy-io/react-auth";
import useWebSocket from "react-use-websocket";

import { config_wsEndpoint } from "src/config";

export default () => {
  const { user } = usePrivy();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    config_wsEndpoint, {
      queryParams: {
        userId: user && user.id
      },
      shouldReconnect   : () => true,
      reconnectInterval : 5000,
    }, !!user
  );

  return { sendMessage, lastMessage, readyState };
};
