import { getAccessToken } from "@privy-io/react-auth";
import axios from "axios";

import { config_domain, config_apiEndpoint } from "src/config";

export const SERVICE_1 = "service1";
export const SERVICE_2 = "service2";
export const SERVICE_ADMIN = "admin";
export const SERVICE_ADMIN_2 = "admin2";

export const callLambda = async ({ method, service, url, body = {}, queryStringParameters = {}, unauthed = false }) => {
  const accessToken = await getAccessToken();
  if (!accessToken && !unauthed) return null;
  const headers = unauthed ? {} : { Authorization: `Bearer ${accessToken}` };
  let slug = "/";
  if (window.location.pathname.match(/\//g).length > 1) {
    // if 2 slashes or more are found in the path, then it means that a slug is present
    slug = window.location.pathname.split("/")[2];
  }
  const { data } = await axios({
    url    : `${config_apiEndpoint}/${service}/${url}`,
    method,
    headers,
    params : {
      ...queryStringParameters,
      domain : config_domain,
      slug,
      t      : Date.now()
    },
    data: body,
  });
  return data;
};

export const fetchAllResult = async ({ service, url }) => {
  const result = [];
  let lastItemKey;

  do {
    const response = await callLambda({
      method : "POST",
      service,
      url,
      body   : {
        pageLimit         : 10000,
        ExclusiveStartKey : lastItemKey
      }
    });

    result.push(...response.Items);
    lastItemKey = response.LastEvaluatedKey;
  } while (lastItemKey);

  return result;
};

export const addNewPrivateAsset = async (portalId, type, data, name, contentType = "image/png") => {
  const { signedUrl, id } = await callLambda({
    method                : "post",
    service               : SERVICE_1,
    url                   : "addNewPrivateAsset",
    body                  : { type, name, contentType },
    queryStringParameters : { portalId }
  });
  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": contentType }
  });
  return id;
};

export const uploadFileToS3BySlug = async (slug, data, name, contentType = "application/pdf") => {
  const { signedUrl, id } = await callLambda({
    method   : "post",
    service  : SERVICE_1,
    url      : "getUserPreSignedUrlBySlug",
    body     : { contentType, name, slug },
    unauthed : true
  });

  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": contentType }
  });

  return id;
};

export const getS3AssetURLRedirect = (token, id) => `${config_apiEndpoint}/${SERVICE_1}/getPreSignedReadUrlRedirect?id=${id}&authToken=${token}`;
export const getS3AssetURLImagesRedirect = (token, portalId, id, key) => `${config_apiEndpoint}/${SERVICE_1}/getPreSignedReadUrlImagesRedirect?id=${id}&authToken=${token}&portalId=${portalId}&key=${key}`;
