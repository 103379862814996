import React from "react";

import { CircularProgress, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import styles from "./styles.module.less";

const style = theme => ({
  margin   : "0px",
  position : "fixed",
  top      : "calc(50% - 24px)",
  left     : "calc(50% - 24px)",
  color    : theme.palette.action.active,
});

const View = ({ text }) => {
  const theme = useTheme();

  return (
    <div className={styles.loadingOverlayDiv}>
      {!text && (
        <div className={styles.loadingCenterWrapper}>
          <CircularProgress style={style(theme)} />
        </div>
      )}
      {text && (
        <div className={styles.loadingCenterWithText}>
          <CircularProgress style={style(theme)} />
          <span className="text">{text}</span>
        </div>
      )}
    </div>
  );
};

View.propTypes = {
  text: PropTypes.string
};

export default View;
