import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setPrivyLogin } from "src/redux/slices/global";

const usePrivyLoginMethod = () => {
  const data = useSelector(store => store.global?.privyLogin);
  const dispatch = useDispatch();

  const setData = useCallback((payload) => {
    dispatch(setPrivyLogin(payload));
  }, [dispatch]);

  return { data, setData };
};

export default usePrivyLoginMethod;
