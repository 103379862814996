import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

import { SERVICE_1, callLambda } from "src/utils/lambda";

export default () => useSWRMutation("updateUser", (url, { arg }) => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "updateUser",
  body    : arg
}), {
  onSuccess: (data) => {
    mutate(
      "accountInfo",
      prev => ({ ...prev,  userItem: data.userItem }),
      { revalidate: false }
    );
  }
});
