import { createComponents } from "./create-components";
import { createPalette } from "./create-palette";
import { createShadows } from "./create-shadows";

export const createOptions = ({ colorPreset, contrast, orgTheme }) => {
  const palette = createPalette({ colorPreset, contrast, orgTheme });
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows
  };
};
