/* eslint no-underscore-dangle: "off" */

import { configureStore } from "@reduxjs/toolkit";

import reducer from "./reducer";

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleWare =  getDefaultMiddleware();

    return defaultMiddleWare;
  }
});

export default store;
