import { createTheme as createMuiTheme, responsiveFontSizes } from "@mui/material/styles";
import merge from "lodash/merge";

import { createOptions as createBaseOptions } from "./base/create-options";
import { createOptions as createDarkOptions } from "./dark/create-options";
import { createOptions as createLightOptions } from "./light/create-options";

export const createTheme = (config) => {
  const options = config.paletteMode === "dark"
  ? createDarkOptions({
    colorPreset : config.colorPreset,
    contrast    : config.contrast
  })
  : createLightOptions({
    colorPreset : config.colorPreset,
    contrast    : config.contrast,
    orgTheme    : config.orgTheme,
  });

  if (config.orgTheme?.primary) {
    options.palette.tonalOffset = {
      light : 0.85,
      dark  : 0.15,
    };
  }

  let theme = createMuiTheme(merge(
    // Base options available for both dark and light palette modes
    createBaseOptions({
      direction: config.direction,
    }),
    // Options based on selected palette mode, color preset and contrast
    options
  ));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
