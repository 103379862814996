import { usePrivy } from "@privy-io/react-auth";
import useSWR from "swr";

const useAuthSWR = (key, fetcher, config) => {
  const { user } = usePrivy();

  // Execute the hook with the new fetcher.
  return useSWR(user ? key : null, fetcher, config);
};

export default useAuthSWR;
