import { common } from "@mui/material/colors";
import { alpha } from "@mui/system/colorManipulator";

import { error, info, neutral, success, warning } from "../colors";

export const createPalette = (config) => {
  const { contrast, orgTheme } = config;
  return {
    action: {
      active             : neutral[500],
      disabled           : alpha(neutral[900], 0.38),
      disabledBackground : alpha(neutral[900], 0.12),
      focus              : alpha(neutral[900], 0.16),
      hover              : alpha(neutral[900], 0.04),
      selected           : alpha(neutral[900], 0.12)
    },
    background: {
      default : contrast === "high" ? neutral[50] : common.white,
      paper   : common.white
    },
    divider   : "#F2F4F7",
    error,
    info,
    mode      : "light",
    neutral,
    primary   : { main: orgTheme?.primary || "#000" },
    secondary : {
      lightest : "#F8F9FA",
      light    : "#F3F4F6",
      main     : "#384250",
      dark     : "#1C2536",
      darkest  : "#111927"
    },
    success,
    text: {
      primary   : neutral[900],
      secondary : neutral[500],
      disabled  : alpha(neutral[900], 0.38)
    },
    warning
  };
};
